





























import { Component, Vue, Watch} from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUserProfile } from '@/interfaces';
import { dispatchGetUsers } from '@/store/admin/actions';
import { 
    dispatchreadAppsForUser, 
    dispatchreadTransactionsForUser, 
    dispatchreadServiceProviders} from '@/store/devs/actions';


@Component
export default class AdminUsers extends Vue {
  public headers = [
    {
      text: 'Sender',
    //   sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'AppCode',
      sortable: true,
      value: 'app_code',
      align: 'left',
    },
    {
      text: 'Service Provider',
      sortable: true,
      value: 'service_provider',
      align: 'left',
    },
    {
      text: 'Date',
      sortable: true,
      value: 'date',
      align: 'left',
    },
    {
      text: 'Amount',
      sortable: false,
      value: 'amount',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
    },
  ];

  private listoftransactions = [];
  private listofproviders = [];
  private providernames = [];
  private providers_id_objects = {};


  public async beforeMount() {
    this.getTransactions();
    this.getServiceProviders();
    this.build_providers_obj();

  }

  public async mounted() {
    console.log(this.providers_id_objects)
  }

//   private selectProvider(id) {
//     this.listofproviders.forEach(provider => {
//         if (provider.id == id) {
//             // console.log(provider)
//             return provider            
//         }
//         else {
//             return "UNKNOWN"
//         }        
//     });
//   }


public getProviderName(id) {
    this.listofproviders.forEach(provider => {
        if (provider['id'] == id){
            console.log(provider['name'])
            
            return provider['name'];

        }
        else {
            return "UNKNOWN"
        }
    });
}

public build_providers_obj(){
  this.listofproviders.forEach(provider => {
        this.providers_id_objects[provider['id']] = provider['name'] 
    });

}

  private async getTransactions() {
    const response = await dispatchreadTransactionsForUser(this.$store);
    if (response && response?.length > 0) {
      this.listoftransactions = response;
    }
  }

  private async getServiceProviders() {
    const response = await dispatchreadServiceProviders(this.$store);
    if (response && response?.length > 0) {
      this.listofproviders = response;
    }
  }


}
